import React from "react"
import styles from "../components/header.module.css"
import Header from "../components/header"
import { Link } from "gatsby"

export default () => (
  <Header>
    <div className={styles.mainheading}>Aviva Melamed</div>
    <div className={styles.subheading}>Humanistic Integrative Counsellor</div>
    <div className={styles.box} />
    <div className={styles.menubox}>
      <ul className={styles.ul}>
        <li className={styles.li}>
          <Link to="/">Home</Link>
        </li>
        <li className={styles.li}>
          <Link to="/about">About Me</Link>
        </li>
        <li className={styles.li}>
          <Link to="/faq">FAQ</Link>
        </li>
        <li className={styles.li}>
          <Link to="/services">Services</Link>
        </li>
        <li className={styles.li}>
          <Link to="/contact">Contact Me</Link>
        </li>
      </ul>
    </div>
    <div className={styles.logo} />
    <div className={styles.about}> About Me</div>
    <div className={styles.abouttext}>
      I am an experienced and qualified Humanistic Integrative Counsellor
      working with clients experiencing: relationship issues, self-esteem,
      family conflict, dealing with loss and grief, anger, depression, cultural
      issues, domestic violence, physical abuse, sexual abuse, OCD, sexuality
      and mental health. I offer my clients a warm, confidential and
      non-judgmental setting where they can start to explore their feelings and
      get to know themselves better. I primarily practice from a
      Humanistic/Gestalt perspective integrating psychodynamic ideas into my
      work. I interact well with people in person and telephonically.
      <br />
      <br />I view each client as an individual and use different approaches to
      help my clients. I help my clients become aware of their unconscious
      thoughts. One of the ways I work is in a creative way, where I use markers
      and papers of different colours and shapes. I use two chair work, imagery
      work, genograms to help my clients to make their feelings more tangible.
      <br />
      <br />
      <b>
        <u>Qualifications</u>
      </b>
      <br />
      • Certificate in Humanistic Integrative Counselling (CPPD) 2014 <br />
      • Diploma in Humanistic Integrative Counselling (CPPD) 2015 <br />
      • Advanced Diploma in Humanistic Integrative Counselling (CPPD) 2017{" "}
      <br />
      <br />
      <b>
        <u>Awards</u>
      </b>
      <br />• Winner of the Norwood Volunteer Professional of the Year Award
      2018
    </div>
  </Header>
)
